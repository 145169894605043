@use "./mixins";
@use "./variables";
@use "sass:color";
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: variables.$font-family-base;
  font-size: variables.$font-size-base;
  font-weight: variables.$font-regular;
  color: variables.$font-color-base;
}
a {
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.clearfix:after {
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  @include mixins.rtl {
    display: block;
    text-align: right;
  }
}

a {
  color: var(--background-color-front);
  transition: all 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
    color: var(--background-secondary-color);
  }
}

img {
  max-width: 100%;
}

th,
td {
  @include mixins.rtl {
    text-align: right;
  }
}
.section {
  padding-top: variables.$section-padding;
  padding-bottom: variables.$section-padding;

  @include mixins.tablet {
    padding-top: variables.$section-tablet-padding;
    padding-bottom: variables.$section-tablet-padding;
  }

  @include mixins.mobile {
    padding-top: variables.$section-mobile-padding;
    padding-bottom: variables.$section-mobile-padding;
  }
}

.btn {
  padding: 16px 40px;
  font-weight: variables.$font-bold;
  line-height: 1;
  @include mixins.rounded(variables.$border-radius);

  &-sm {
    padding: 12px 20px;
  }

  &-xs {
    padding: 7px 10px;
    font-size: 13px;
  }

  &:focus {
    box-shadow: none !important;
  }

  &.btn-primary,
  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active {
    background-color: var(--background-color-front);
    border-color: var(--background-color-front);
    color: white;

    &:hover,
    &:active {
      background-color: var(--background-secondary-color);
      border-color: var(--background-secondary-color);
      color: white;
    }
  }

  &.btn-outline-primary {
    color: var(--background-color-front);
    border-color: var(--background-color-front);

    &:hover {
      color: variables.$white;
      background-color: var(--background-color-front);
    }
  }
}

.btn-fw-200 {
  width: 200px;
}
.card-title {
  @include mixins.rtl {
    text-align: right;
  }
}

.react-select-container {
  .react-select {
    &__control {
      min-height: variables.$input-height;
      border: variables.$input-border solid variables.$input-border-color;
      box-shadow: none;

      &:hover {
        border-color: variables.$input-border-color;
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__menu {
      margin-top: 2px;
      z-index: 3;
    }

    &__option {
      background-color: variables.$white;

      &:hover {
        background-color: color.scale(#ddd, $lightness: 10%);
      }

      &--is-selected {
        background-color: color.scale(#ddd, $lightness: 7%);
        color: variables.$font-color-base;

        &:hover {
          background-color: color.scale(#ddd, $lightness: 7%);
          color: variables.$font-color-base;
        }
      }
    }
  }
}

.form-control {
  height: variables.$input-height;
  @include mixins.rounded(variables.$border-radius);
  border: variables.$input-border solid variables.$input-border-color;
  color: variables.$font-color-base;

  &:focus {
    border-color: variables.$input-border-color;
    @include mixins.box-shadow(0, 2px, 4px, rgba(67, 54, 102, 0.1));
  }
}
.form-group {
  @include mixins.rtl {
    text-align: right;
  }
}
.custom-file {
  height: variables.$input-height;
  position: relative;

  &-input {
    height: variables.$input-height;
    min-height: variables.$input-height;
  }

  &-label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: variables.$input-height;
    @include mixins.rounded(variables.$border-radius);
    border: variables.$input-border solid variables.$input-border-color;

    &::after {
      height: calc(var(--input-height) - var(--input-border));
      font-weight: variables.$font-bold;
      font-size: 15px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;

      @include mixins.rtl {
        left: 0;
        right: auto;
        border-radius: 0.25rem 0 0 0.25rem;
      }
    }
  }
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: variables.$input-border-color;
  @include mixins.box-shadow(0, 2px, 4px, rgba(67, 54, 102, 0.1));
}
.custom-file-label {
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  height: 100%;
  overflow: hidden;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label,
.custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}

.custom-file-label:after {
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  height: 100%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.form-control.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.form-control.is-invalid{
  @include mixins.rtl {
    padding-right: 0 ;
    padding-left: calc(1.5em + .75rem) ;

  }
}

.custom-checkbox {
  @include mixins.rtl {
    padding-left: 0;
    padding-right: 1.5rem;
  }
}
.custom-control-label::before {
  border-color: variables.$input-border-color;
}
.is-invalid {
  & [type="checkbox"] {
    border-color: #dc3545;

    & input:focus {
      outline: 2px solid red;
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
    }
  }
}

.form-check-input:checked {
  background-color: var(--background-color-front);
  border-color: var(--background-color-front);
}
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-label::before,
.custom-control-label::after {
  @include mixins.rtl {
    left: unset;
    right: -1.5rem;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--background-color-front);
  background-color: var(--background-color-front);
}

.custom-control-input {
  @include mixins.rtl {
    left: auto;
    right: 0;
  }
}

.form-label {
  font-weight: variables.$font-medium;
  @include mixins.rtl {
    display: block;
    text-align: right;
  }
}

.label {
  @include mixins.rtl {
    display: block;
    text-align: right;
  }
}

.invalid-feedback {
  @include mixins.rtl {
    text-align: right;
  }
}
.text-right {
  text-align: right !important;
  @include mixins.rtl {
    text-align: left !important;
  }
}
.progress {
  background-color: #eaeef3;

  .progress-bar {
    background-color: var(--background-color-front);
  }
}

.fc-light {
  color: variables.$font-color-light;
}

.fc-base {
  color: variables.$font-color-base;
}

.avatar-xl {
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.avatar-lg {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.avatar-md {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.avatar-sm {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.avatar-xsm {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.avatar-25 {
  width: 25px;
  height: 25px;
  object-fit: cover;
}

.avatar-rounded {
  border-radius: 50%;
}

.avatar-radius-5 {
  border-radius: 5px;
}

.text-primary {
  color: var(--background-color-front) !important;
}
.text-important {
  color: variables.$text-important;
}
.react-select-container {
  &.is-invalid {
    .react-select__control {
      border-color: #dc3545;
    }
  }
}

.is-invalid {
  @include mixins.rtl {
    background-position: left calc(0.375em + 0.1875rem) center !important;
  }
}

.custom {
  &-closing {
    &-icon {
      color: variables.$red;
    }
  }
}

.project {
  &-card {
    &-ending-soon {
      font-size: 15px;
      font-weight: 500;
    }
  }
}
.custom-file-label {
  overflow: hidden;
  white-space: nowrap;
  // For nice text overflow we need also padding-right, problem is
  // the button width is not constant, but 8em should work for most cases
  padding-right: 8em;
  text-overflow: ellipsis;

  @include mixins.rtl {
    padding-left: 8em;
    padding-right: 0.75rem;
  }
  [lang="ar"] &::after {
    content: "تصفح" !important;
  }
  [lang="fr"] &::after {
    content: "Parcourir" !important;
  }
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 3%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown
  .react-datepicker__year-option:last-child::before {
  content: "\25BC";
  display: block;
  position: absolute;
  left: 45%;
  bottom: 3%;
}

.react-toggle--focus {
  .react-toggle-thumb {
    box-shadow: none !important;
  }
}
input[type="radio"].form-control:disabled {
  opacity: 0;
}
.PhoneInput {
  .PhoneInputCountrySelectArrow {
    margin-right: 5px;
  }
  .PhoneInputInput {
    @include mixins.rtl {
      text-align: right;
    }
  }
}

[dir="rtl"] .form-check {
  text-align: right;
}

[dir="rtl"] .form-control {
  text-align: right;
}
