//
// Mixins
//

@use "./variables";
@use "./mixins";
@use "sass:color";
@use "sass:math";

@mixin spinner-size($size) {
	&:before {
		width: $size;
		height: $size;
		margin-top: -(math.div($size, 2));
	}

	&.spinner-center {
		&:before {
			left: 50%;
			margin-left: -(math.div($size, 2));
		}
	}

	&.spinner-left {
		&:before {
			right: auto;
		}
	}

	&.spinner-right {
		&:before {
			left: auto;
		}
	}
}

@mixin spinner-theme($color, $important: false) {
	&:before {
		border-color: $color valueif($important, !important, null);
		border-right-color: transparent;
	}
}

//
// Spinner
//

// variables
$spinner-size: 1.5rem;
$spinner-size-sm: 1.25rem;
$spinner-size-lg: 2rem;
$gray-400: #d6d6e0 !default;

// Spinner Default
.spinner {
	position: relative;

	&:before {
		content: "";
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 0;
		border-radius: 50%;
		border: 2px solid $gray-400;
		border-right: 2px solid transparent;
	}

	// Sizes
	@include spinner-size($spinner-size);

	&.spinner-sm {
		@include spinner-size($spinner-size-sm);
	}

	&.spinner-lg {
		@include spinner-size($spinner-size-lg);
	}

	// Default Style
	&:before {
		animation: animation-spinner 0.5s linear infinite;
	}

	// State Colors

	// Alignment
	&.spinner-right {
		&:before {
			left: auto;
			right: variables.$input-btn-padding-x;
		}

		&.btn:not(.btn-block) {
			padding-right: 2 * variables.$input-btn-padding-x + $spinner-size;
		}

		&.btn:not(.btn-block).spinner-sm {
			padding-right: 2 * variables.$input-btn-padding-x + $spinner-size-sm;
		}

		&.btn:not(.btn-block).spinner-lg {
			padding-right: 2 * variables.$input-btn-padding-x + $spinner-size-lg;
		}
	}

	&.spinner-left {
		&:before {
			right: auto;
			left: variables.$input-btn-padding-x;
		}

		&.btn:not(.btn-block) {
			padding-left: 2 * variables.$input-btn-padding-x + $spinner-size;
		}

		&.btn:not(.btn-block).spinner-sm {
			padding-left: 2 * variables.$input-btn-padding-x + $spinner-size-sm;
		}

		&.btn:not(.btn-block).spinner-lg {
			padding-left: 2 * variables.$input-btn-padding-x + $spinner-size-lg;
		}
	}

	// Spinner Track
	&.spinner-track {
		&:before {
			animation: animation-spinner 0.5s linear infinite;
		}
	}
}

// Animations
@keyframes animation-spinner {
	to {
		transform: rotate(360deg);
	}
}
