@use "../../assets/sass/variables";
@use "../../assets/sass/mixins";
@use "sass:color";

.footer-wrapper {
	background-color: variables.$footer-bg;
	border-top: 1px solid #d6d6d7;
	padding-top: 80px;
	padding-bottom: 50px;
	@include mixins.tablet {
		padding-top: 50px;
		padding-bottom: 16px;
		padding-right: 35px;
	}
	@include mixins.medium-small-width {
		padding-top: 30px;
		padding-bottom: 16px;
	}
	@include mixins.rtl {
		@include mixins.tablet {
			padding-left: 75px;
		}
	}
}

.footer-heading {
	color: #a7a7a7;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: variables.$font-regular;
	margin-bottom: 30px;
	@include mixins.mobile {
		margin-bottom: 5px;
	}
}
.react-select__input {
	color: transparent;
}
.footer-link-wrapper {
	@include mixins.rtl {
		text-align: right;
	}
	.footer-link-item {
		margin-bottom: 18px;
		font-size: 14px;
		@include mixins.mobile {
			margin-bottom: 8px;
			font-size: 13px;
		}

		.footer-link {
			color: variables.$footer-link-color;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.social-link-wrapper {
	display: flex;
	margin-bottom: 30px;
	flex-wrap: wrap;

	.social-link {
		border: 1px solid #9e9e9e;
		padding: 10px;
		flex: 0 0 40px;
		max-width: 40px;
		text-align: center;
		margin-right: 10px;
		color: #9e9e9e;
		border-radius: variables.$border-radius;
		line-height: 1;
		transition: all 300ms ease-in-out;

		@include mixins.rtl {
			margin-right: 0;
			margin-left: 10px;
		}

		&[title="Facebook"]:hover {
			border-color: variables.$brand-facebook;
			color: variables.$brand-facebook;
		}

		&[title="Facebook-Messenger"]:hover {
			border-color: variables.$brand-facebook;
			color: variables.$brand-facebook;
		}

		&[title="Twitter"]:hover {
			border-color: variables.$brand-twitter;
			color: variables.$brand-twitter;
		}

		&[title="Telegram"]:hover {
			border-color: variables.$brand-twitter;
			color: variables.$brand-twitter;
		}

		&[title="Linkedin"]:hover {
			border-color: variables.$brand-linkedin;
			color: variables.$brand-linkedin;
		}

		&[title="Youtube"]:hover {
			border-color: variables.$brand-youtube;
			color: variables.$brand-youtube;
		}

		.social-icon {
			font-size: 18px;
		}
	}
}
.change-language {
	width: 190px;

	.react-select {
		&__control {
			background-color: transparent;
		}
		&__option {
			&:hover {
				background-color: color.scale(#ddd, $lightness: 5%);
			}

			&--is-selected {
				background-color: var(--background-color-front);
				color: white;

				&:hover {
					background-color: var(--background-color-front);
					color: white;
				}
			}
		}
	}
}

.footer-disclaimer,
.footer-copyright {
	border-top: 1px solid variables.$gray;
	font-size: 85%;
	padding-top: 15px;
	margin-top: 50px;
}

.custom-footer-link {
	color: #31333d;
	text-decoration: none;
	font-size: 14px;
	@include mixins.mobile {
		font-size: 13px;
	}
}
.custom-footer-link:hover {
	color: #31333d;
	text-decoration: underline;
}

.footer-main-wrap {
	@include mixins.mobile {
		margin-bottom: 15px;
	}
}

.swal2-confirm {
	background-color: var(--background-color-front) !important;
}
