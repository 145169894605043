@use "../../assets/sass/variables";
@use "../../assets/sass/mixins";
@use "sass:color";

.ec-navbar {
  background-color: variables.$white;
  @include mixins.box-shadow(0, 0, 30px, rgba(67, 54, 102, 0.1));
  padding: 20px;
  @include mixins.mobile {
    padding: 20px 10px;
  }
  .navbar-nav {
    align-items: center;
    @include mixins.rtl {
      margin-left: 0 !important;
      margin-right: auto;
    }

    @include mixins.tablet {
      display: block;
      margin-top: 10px;
    }

    .nav-link {
      margin-left: 30px;
      color: variables.$font-color-dark;

      @include mixins.rtl {
        margin-right: 30px;
        margin-left: 0 !important;
      }
      .top-notification-count {
        position: absolute;
        top: -4px;
        right: -6px;
        display: grid;
        place-content: center;
        background-color: red;
        color: variables.$white;
        font-size: 10px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
      &.nav-user-dropdown-wrapper,
      &.top-notification {
        position: relative;

        @include mixins.rtl {
          margin-right: 1rem !important;
          margin-left: 0 !important;
        }
      }

      &:hover {
        color: var(--background-color-front);
      }

      &.btn {
        padding: 13px 30px;
        color: variables.$white;
        font-weight: variables.$font-regular;
        @include mixins.tablet {
          display: inline-block;
        }
      }

      @include mixins.tablet {
        margin-left: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        border-top: 1px solid #ddd;

        &:first-child {
          border: 0;
        }
      }
    }
  }
}

.nav-user {
  &-dropdown-wrapper {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
  }
  &-info {
    @include mixins.rtl {
      padding-bottom: 30px;
    }
  }
  &-img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: variables.$btn-border-radius;
    @include mixins.rtl {
      float: right;
    }
  }

  &-name {
    display: none;
    @include mixins.rtl {
      float: right;
      margin-right: 10px;
      padding-top: 10px;
    }
  }

  &-dropdown {
    position: absolute;
    max-width: fit-content;
    z-index: 10;
    top: 120%;
    padding-top: 10px;
    right: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;

    @include mixins.rtl {
      left: 0;
      right: auto;
    }

    &-inner {
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);
      @include mixins.rounded(variables.$border-radius);
      padding: 10px 0;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 100%;
        right: 20px;
        height: 0;
        width: 0;
        pointer-events: none;

        @include mixins.rtl {
          left: 30px;
          right: auto;
        }
      }

      &::before {
        margin-left: -9px;
        border: 9px solid transparent;
        border-bottom-color: rgba(0, 0, 0, 0.05);
      }

      &::after {
        margin-left: -8px;
        border: 8px solid transparent;
        border-bottom-color: #fff;
      }
    }

    &-item {
      display: block;
      color: variables.$font-color-base;
      padding: 10px 20px;

      &:hover {
        background-color: color.scale(#ddd, $lightness: 7%);
        color: variables.$font-color-base;
      }
    }
  }
}

.nav-user-dropdown-wrapper:hover .nav-user-dropdown {
  visibility: visible;
  opacity: 1;
  padding-top: 20px;
}

.unread-number {
  // background-color: variables.$red;
  color: var(--background-color-front) !important;
  padding-top: 3px;
  padding-bottom: 3px;
  @include mixins.rounded(variables.$border-radius);
}

.nav-user-arrow {
  display: none;
  font-size: 1rem;
  @include mixins.rtl {
    position: absolute;
    font-size: 1.5rem;
    right: 95%;
    padding-top: 7px;
  }
}

.dropdown-item-messages,
.dropdown-item-notifications {
  display: none;
}

@include mixins.tablet {
  .top-messages,
  .top-notification {
    display: none;
  }

  .nav-user-dropdown-wrapper {
    margin-left: 0 !important;
  }

  .nav-user-name {
    display: initial;
    margin-left: 15px;
    font-weight: variables.$font-bold;
  }

  .nav-user-info {
    position: relative;
  }

  .nav-user-arrow {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    opacity: 0.5;
  }

  .nav-user-dropdown {
    position: relative;
    width: 100%;
    z-index: 10;
    height: 0;
  }

  .nav-user-dropdown-wrapper:hover .nav-user-dropdown {
    height: auto;
  }

  .nav-user-dropdown-inner {
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    position: relative;
  }

  .nav-user-dropdown-item {
    padding-left: 0;
    padding-right: 0;
  }

  .dropdown-item-messages,
  .dropdown-item-notifications {
    display: block;
  }
}

.primary-site-logo {
  max-height: 50px;
  max-width: 220px;
}
