@use "./variables";
@use "./mixins";
@use "sass:color";

.section-brdr-top {
  border-top: 1px solid #cbcbcb;
}
.mb-30 {
  margin-bottom: 30px;
}

.py-75 {
  padding-top: 75px;
  padding-bottom: 75px;
}

.section-title {
  font-size: 30px;
  font-weight: variables.$font-regular;
  @include mixins.medium-small-width {
    text-align: center;
  }

  &-wrapper {
    margin-bottom: 25px;
  }

  &-brif {
    font-size: 15px;
    color: variables.$font-color-light;
    margin-bottom: 0;
    margin-top: 5px;
  }
}

.fs-15 {
  font-size: 15px;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

.pagination {
  .page-item {
    margin-left: 5px;

    .page-link {
      border: 0;
      font-weight: variables.$font-bold;
      color: variables.$font-color-base;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      padding: 8px;
      text-align: center;
      font-size: 14px;

      &:hover {
        background-color: color.scale(#ddd, $lightness: 7%);
      }
    }

    &.active {
      .page-link {
        background-color: var(--background-color-front);
        color: variables.$white;
      }
    }
  }
}

.custom-input-group {
  position: relative;

  &-text {
    font-weight: variables.$font-bold;
    color: variables.$font-color-light;
    position: absolute;
    // height: 100%;
    top: 13px;
    display: flex;
    align-items: center;
    min-width: 35px;
    padding: 0 10px;
    justify-content: center;
  }

  &-prepend {
    .custom-input-group-text {
      left: 0;
      @include mixins.rtl {
        right: 0;
        left: auto;
      }
    }

    .form-control {
      padding-left: 35px;
      @include mixins.rtl {
        padding-right: 35px;
      }
    }
  }

  &-append {
    .custom-input-group-text {
      right: 0;
      @include mixins.rtl {
        left: 0;
        right: auto;
      }
    }
    .form-control {
      padding-right: 35px;
      @include mixins.rtl {
        padding-left: 35px;
      }
    }
  }
}

.form-separator {
  margin-top: 50px;
  margin-bottom: 50px;
}

.action-btn-group {
  .btn {
    &.btn-sm {
      padding: 7px 10px;
      width: 35px;
      height: 35px;

      &:not(:hover) {
        background-color: #f3f6f9;
        border-color: #f3f6f9;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    max-width: 220px;
    padding: 10px 18px;
    color: variables.$font-color-base;
    background-color: variables.$white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  }
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before,
.bs-tooltip-bottom .arrow:before {
  border-bottom-color: variables.$white;
}

.ec-custom-file {
  position: relative;

  &-input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
}

.question-mark {
  cursor: pointer;
  margin-left: 7px;
  font-size: 14px;
  @include mixins.rtl {
    margin-right: 7px;
  }
}

.custom-btn-group {
  &.custom-btn-group-lg {
    .custom-btn-group-item {
      .custom-btn-group-label {
        padding: 12px 25px;
      }
    }
  }

  .custom-btn-group-item {
    &:first-child {
      .custom-btn-group-label {
        border-top-left-radius: variables.$border-radius;
        border-bottom-left-radius: variables.$border-radius;

        @include mixins.rtl {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          border-top-right-radius: variables.$border-radius;
          border-bottom-right-radius: variables.$border-radius;
        }
      }
    }

    &:last-child {
      .custom-btn-group-label {
        border-top-right-radius: variables.$border-radius;
        border-bottom-right-radius: variables.$border-radius;

        @include mixins.rtl {
          border-top-left-radius: variables.$border-radius;
          border-bottom-left-radius: variables.$border-radius;

          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }

  .custom-btn-group-input {
    &:checked {
      & ~ .custom-btn-group-label {
        background-color: var(--background-color-front);
        border-color: var(--background-color-front);
        color: #fff;

        &.custom-btn-group-danger {
          background-color: variables.$red;
          border-color: variables.$red;
          color: #fff;
        }

        &.custom-btn-group-secondary {
          background-color: variables.$btn-secondary;
          border-color: variables.$btn-secondary;
          color: #fff;
        }
      }
    }
  }
}

.custom-btn-group {
  display: flex;

  &-item {
    position: relative;
    margin-left: -1px;
  }

  &-input {
    position: absolute;
    opacity: 0;
    overflow: hidden;
  }

  &-label {
    transition: all 200ms ease-in-out;
    border: 1px solid variables.$gray;
    text-align: center;
    padding: 6px 18px;
    cursor: pointer;
    margin-bottom: 0;
  }
}

.top-banner-wrapper {
  padding-top: 110px;
  padding-bottom: 110px;
  position: relative;

  @include mixins.tablet {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .top-banner-bgImg {
    background-size: cover;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.campaign-title {
  @include mixins.mobile {
    text-align: center;
    margin-top: 30px;
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 60px;

    @include mixins.mobile {
      justify-content: center;
    }
  }

  &-img {
    @include mixins.box-shadow(0, 2px, 5px, rgba(67, 54, 102, 0.15));
    background-color: variables.$white;
    @include mixins.rounded(4px);
    overflow: hidden;
    flex: 0 0 98px;
    max-width: 98px;
    margin-right: 22px;

    @include mixins.mobile {
      margin-right: 0;
    }

    img {
      width: 98px;
      height: 98px;
      object-fit: cover;
    }
  }

  &-name {
    color: variables.$white;
    font-size: 35px;
    font-weight: variables.$font-bold;
  }

  &-brif {
    color: variables.$white;
  }

  &-category {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;

    @include mixins.mobile {
      justify-content: center;
    }

    &-item {
      padding: 3px 10px;
      color: variables.$white;
      font-size: 12px;
      text-transform: uppercase;
      border: 1px solid variables.$white;
      border-radius: 4px;
      opacity: 0.75;
      margin-right: 10px;

      @include mixins.mobile {
        margin-bottom: 10px;
      }
    }
  }
}
.share-dropdown-wrapper {
  position: relative;
}

.share-dropdown {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 0 rgba(0, 0, 0, 0.02);
  @include mixins.rounded(variables.$border-radius);
  padding: 10px 0;
  position: absolute;
  width: 150px;
  top: 100%;
  margin-top: 5px;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    height: 0;
    width: 0;
    pointer-events: none;
  }

  &::before {
    margin-left: -9px;
    border: 9px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.05);
  }

  &::after {
    margin-left: -8px;
    border: 8px solid transparent;
    border-bottom-color: #fff;
  }

  &-icon {
    margin-right: 0.5rem !important;
    @include mixins.rtl {
      margin-left: 0.5rem !important;
    }
  }

  a {
    display: block;
    color: variables.$font-color-base;
    padding: 7px 20px;
    text-align: left;
    @include mixins.rtl {
      text-align: right;
    }
    &:hover {
      background-color: color.scale(#ddd, $lightness: 7%);
      color: variables.$font-color-base;
    }
  }
}

.share-dropdown-wrapper:hover .share-dropdown {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}

.top-investors-wrapper {
  @include mixins.mobile {
    margin-top: 30px;
  }
  .media {
    border: none;
    @include mixins.rounded(variables.$border-radius);
    padding: 20px;
    margin-bottom: 20px;
    transition: all 300ms ease-in-out;
    border-radius: 0.8rem;
    box-shadow: 0 0 17px rgba(111, 111, 111, 0.08);
    height: 100%;
    align-items: stretch;

    &:hover {
      @include mixins.box-shadow(0, 4px, 5px, rgba(67, 54, 102, 0.1));
    }

    &:last-child {
      margin-bottom: 0;
    }

    .rounded-circle {
      border: 8px solid #ebe8ff;
      object-fit: cover;
    }

    .media-body {
      h5 {
        font-size: 20px;
        font-weight: variables.$font-bold;
        margin-bottom: 5px;
      }

      p {
        color: variables.$font-color-light;
        margin-bottom: 0;
        margin-top: 5px;

        strong {
          color: variables.$font-color-base;
        }

        a {
          word-break: break-word;
          color: variables.$font-color-base;
          text-decoration: underline;
          font-weight: variables.$font-bold;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
.react-select__option--is-focused:not(.react-select__option--is-selected) {
  background-color: rgba(90, 97, 105, 0.10196078431372549);
}

.characters-limit {
  position: absolute;
  bottom: -9px;
  right: 15px;
  font-size: 10px;
  border: 1px solid #ced4da;
  padding: 4px 10px;
  line-height: 1;
  background-color: #fff;
  border-radius: 20px;
  display: none;
}

.form-control {
  &:focus ~ .characters-limit {
    display: block;
  }
  &.no-invalid-icon {
    //background: transparent;
    background-position: right 30px center;
    @include mixins.rtl {
      background-position: left 30px center !important;
    }
  }
}

.project-card {
  border: none;
  box-shadow: 0 0 21px #6f6f6f3d;
}

.ribbon-warning {
  span {
    background-color: #ffc107;
    color: #000000;
    border-bottom: 1px solid #ffbdbd;
  }
}

.ribbon-success {
  span {
    background-color: #28a745;
    color: #ffffff;
    border-bottom: 1px solid #ffbdbd;
  }
}

.ribbon-primary {
  span {
    background-color: #ff0000;
    color: #ffffff;
    border-bottom: 1px solid #ffbdbd;
  }
}

.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: -10px;
  right: -10px;
  span {
    width: 158px;
    position: absolute;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    left: -18px;
    top: 27px;
    transform: rotate(45deg);
  }
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  background-color: #ba0000;
}

.ribbon-success:before,
.ribbon-success:after {
  background: #2a762e;
}

.ribbon-warning:before,
.ribbon-warning:after {
  background: #d3aa19;
}

.ribbon {
  &::before {
    top: 0px;
    left: 5px;
    width: 17px;
    height: 21px;
  }
  &:after {
    bottom: 5px;
    right: 0;
    height: 17px;
    width: 10px;
  }
}

.custom-control {
  z-index: 0;
}

.phone-mobile-center {
  @include mixins.mobile {
    text-align: center;
  }
}

.raise-details-item {
  .fc-light {
    @include mixins.mobile {
      max-width: 300px;
    }
    @include mixins.md-mobile {
      max-width: 200px;
    }
    @include mixins.sm-mobile {
      max-width: 100%;
      text-align: center;
    }
  }
}

.btn {
  @include mixins.rounded(variables.$btn-border-radius);
}

.campaign-banner-wrap {
  .campaign-media-img {
    background-color: rgba(255, 255, 255, 0.4);
    @include mixins.rounded(variables.$box-medium-radius);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.22);
  }
}

.background-box {
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 50px 10px;
}

.section-p-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.documents-title {
  @include mixins.medium-small-width {
    line-height: 1.5rem;
  }
}

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0 !important;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.form-control.PhoneInput {
  display: flex;
}
.PhoneInputInput {
  border: none;
  &:focus-visible {
    outline: none;
  }
}
